/* eslint-disable */
const agency = {};
agency.themeName = 'agency-theme';
agency.clientFolder = 'agency';
agency.title = 'Agency Auto Insurance';
agency.logoAlt = 'agency';
agency.logoImg = '';
agency.logoImgSecondary = '';
agency.phonePrimary = '(555) 555-5555';
agency.phonePrimaryCall = '1-###-###-####';
agency.rc2HeadLogoCaption = 'CarInsurance recommends';

agency.customHeader = 'pemco';
agency.customTitle = 'CarInsurance';

agency.redirection = {
	CreateQuote: {
		patchAPI: '/quotes',
	},
	InsuranceInfo: {
		patchAPI: '#',
		pageAPI: 'pages/AFAutoIntro/',
	},
	VehicleInfo: {
		patchAPI: '#',
		pageAPI: 'pages/AFAutoVehicles/',
		pageAPIRC2: 'pages/WL2AutoRC2Vehicles/',
	},
	DriverInfo: {
		patchAPI: '#',
		pageAPI: 'pages/AFAutoDrivers/',
		pageAPIRC2: 'pages/WL2AutoRC2Drivers/',
	},
	DetailsInfo: {
		patchAPI: '#',
		pageAPI: 'pages/AFAutoDetails/',
		pageAPIRC2: '/pages/WL2AutoRC2PolicyDetails/',
	},
	Quotes: {
		patchAPI: '#',
		pageAPI: 'pages/wlautopriorinsurance/',
	},
	AddVehicle: {
		patchAPI: '#',
		pageName: 'AFAutoNewVehicle',
		pageAPI: 'rules/AddNewVehicle/QTE_VEH__Veh',
	},
	AddDriver: {
		patchAPI: '#',
		pageName: 'AFAutoNewDriver',
		pageAPI: 'rules/AddNewDriver/QTE_DRV__Drv',
	},
	AddIncident: {
		patchAPI: '#',
		pageName: 'AFAutoNewIncident',
		pageAPI: 'rules/AddNewQuoteIncident/QTE_QIN__Qin',
	},
	DeleteVehicle: {
		patchAPI: '#',
		pageAPI: 'rules/RemoveVehicle/',
	},
	DeleteDriver: {
		patchAPI: '#',
		pageAPI: 'rules/RemoveDriver/',
	},
	DeleteIncident: {
		patchAPI: '#',
		pageAPI: 'rules/RemoveQuoteIncident/',
	},
	VehicleInsights: {
		patchAPI: '#',
		pageAPI: 'api/VehicleInsights',
	},
	Checkout: {
		patchAPI: '#',
		pageAPI: 'pages/WL2AutoCheckout/',
	},
	Confirmation: {
		patchAPI: '#',
		pageAPI: 'pages/WL2AutoConfirmation/',
	},
	IpToZip: {
		pageAPI: 'api/ip2geo',
	},
	Zipfinder: {
		pageAPI: 'api/zipfinder',
	},
};

agency.vgs = {
	styles: {
		base: {
			border: '1px solid #BDBDBD',
			background: 'initial',
			'background-color': '#fff',
			'border-radius': '4px !important',
			'font-size': '1rem',
			color: '#757575',
			height: '50px',
			'font-family': "'Roboto', sans-serif",
			padding: '5px 8px 0 8px !important',
			transition:
				'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out',
			width: '100%',
			margin: '3px 0px 0px',
			'&:focus': {
				'background-color': '#fff',
				border: '2px solid #212121',
				// 'box-shadow': '0px 0px 4px 3px #949494 !important',
				// '-webkit-box-shadow': '0px 0px 4px 3px #949494 !important',
				'&.invalid.touched': {
					'border-color': '#BB0000',
				},
			},
			'::placeholder': {
				color: '#999',
			},
			'::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
			},
			'::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
			},
			'&.invalid.touched': {
				border: '1px solid #BB0000',
			},
			'&.invalid.dirty': {
				border: '1px solid #BB0000',
			},
		},
		cardIcon: {
			right: '16px',
			top: '28px',
		},
	},
};
agency.errorMessages = {
	rateFail: `<p><b>Oh no! Progressive was unable to provide a final premium.</b></p>
  <p>For assistance, speak with one of our licensed insurance agent at <a href="tel:19414622045">1-(941)-462-2045</a></p>`,
	orderFail: `<p><b>Oh no! Progressive was unable to to place your order.</b></p>
  <p>For assistance, speak with one of our licensed insurance agent at <a href="tel:19414622045">1-(941)-462-2045</a></p>`,
	networkFail: `<p><strong>500 Error - Oh no! We're sorry.</strong></p><p>It looks like something's wrong with this page. While we're diligently trying to fix this issue. For assistance, speak with one of our licensed insurance agents at <a href="tel:19414622045">1-(941)-462-2045</a>.</p>`,
};

// New agency flow start
agency.name = 'CarInsurance';
agency.headLine = 'Compare <span class="c-color">Top Carriers</span> with Agents Who care';
agency.zipHeadLine = '<span class="fw-bold">Enter your zip</span> to get essential coverage at a great price';
agency.address = 'San Mateo, CA';
agency.offerText = 'Switch &amp; save upto $200/yr. <br />with CarInsurance';
agency.representativeName = 'Kevin Wong';
agency.agencyDescription =
	'Our vast network of providers, including Progressive delivers competitive rates you can trust.';
agency.agencyAddress = 'San Mateo, CA 94403';
agency.shoppingList = [
	{
		id: 1,
		title: 'Bundle (Car & Home) Insurance',
	},
	{
		id: 2,
		title: 'Full coverage policies',
	},
	{
		id: 3,
		title: 'Non-Standard Insurance',
	},
	{
		id: 4,
		title: 'Minimum converage plans',
	},
	{
		id: 5,
		title: 'Rideshare Insurance',
	},
	{
		id: 6,
		title: 'Young Drivers Insurance',
	},
];
agency.whyInsurance = 'Why Choose CarInsurance?';
agency.whyInsuranceSubText = 'When it comes to insurance, there are a lot of choices out there. Here at <span class="fw-bold">CarInsurance</span>, we&#39;re dedicated to make your insurance shopping much, much easier.'
agency.chooseInsuranceList = [
	{
		id: 1,
		title: 'Self & Fast Shooping',
		info: 'Get custom, premium insurance rates in a secure, online place',
	},
	{
		id: 2,
		title: 'Licensed Agent Support',
		info: 'Connect with one of our neighborhood agents to secure the best policy',
	},
	{
		id: 3,
		title: 'Free Quotes, No Commitment',
		info: 'Shop, compare, and purchase policies all online – no strings attached',
	},
	{
		id: 4,
		title: 'Trusted by Drivers Like You',
		info: 'We’ve aimed at matching drivers with their best-suited policy for 30+ years',
	},
	{
		id: 5,
		title: 'Savings You Can’t Skip Out On',
		info: 'With our help, drivers who switch save up to $200/yr. on insurance',
	},
	{
		id: 6,
		title: 'Insure Beyond the Drive',
		info: 'At CarInsurance, we can help you get coverage for your home, pet, and more!',
	},
];
agency.howItWorksTitle = 'How It Works'
agency.howItWorks = [
	{
		id: 1,
		img: 'basic-info-icon',
		title: 'Step 1:',
		info: '<strong>Provide basic information</strong> to secure a premium rate',
	},
	{
		id: 2,
		img: 'shop-compare-icon',
		title: 'Step 2:',
		info: '<strong>Shop & compare</strong> your customized rates from each matched carrier',
	},
	{
		id: 3,
		img: 'purchase-icon',
		title: 'Step 3:',
		info: '<strong>Select & purchase</strong> your policy, all online',
	},
	{
		id: 4,
		img: 'agentsupport-icon',
		title: 'Agent Support Through Every Step',
		info: 'We understand deciding on a policy can be tricky. <strong>Our agents are standing by</strong> to help ensure you have the right coverage at the right price!',
	},
];
agency.readyToGetStartd = 'Ready to Get Started?';
agency.zipHeadLine2 = '<span class="fw-bold">Enter your ZIP</span> to compare competitive coverages side - by - side!';
agency.CTAtitle2 = 'Shop Free Quotes'
agency.contact = [
	{
		id: 1,
		category: 'ADDRESS',
		value: '143 Day Ave. San Mateo, CA 94403',
	},
	{
		id: 2,
		category: 'PHONE',
		value: '<a class="c-color" href="tel:(650) 430-4567" >(650) 430-4567</a>',
	},
	{
		id: 3,
		category: 'WEBSITE',
		value:
			'<a class="c-color" href="https://www.carinsurance.com/" target="_blank">www.CarInsurance.com</a>',
	},
];
agency.officeHoursTitle = 'OFFICE HOURS';
agency.officeHours = [
	{
		id: 1,
		day: 'Monday',
		availability: 'Open until 5:30 PM',
	},
	{
		id: 2,
		day: 'Tuesday',
		availability: '8:30 AM - 5:30 PM',
	},
	{
		id: 3,
		day: 'Wednesday',
		availability: '8:30 AM - 5:30 PM',
	},
	{
		id: 4,
		day: 'Thursday',
		availability: '8:30 AM - 5:30 PM',
	},
	{
		id: 5,
		day: 'Friday',
		availability: '8:30 AM - 5:30 PM',
	},
	{
		id: 6,
		day: 'Saturday',
		availability: 'Closed',
	},
	{
		id: 7,
		day: 'Sunday',
		availability: 'Closed',
	},
];

agency.aboutAgencyInsuranceTitle = 'CarInsurance';
agency.aboutAgencyInsuranceDescription = [
	{
		id: 1,
		info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
	},
	{
		id: 2,
		info: 'Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
	},
	{
		id: 3,
		info: 'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
	},
];
agency.shParameters={
		primaryColor:'212121', 
		buttonFontColor:'ffffff', 
		buttonHoverColor:'BB0000',
		buttonColor:'BB0000'
}
// New agency flow end

export default agency;
