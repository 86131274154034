<template>
  <div>
    <router-view v-slot="{ Component }" v-if="this.$route.name === 'Waffle' || this.$route.name === 'businessinsurance'">
      <transition :name="this.transition" mode="out-in">
        <component :is="Component" :key="$route.fullPath"></component>
      </transition>
    </router-view>
    <component v-else :is="appwrapper"></component>
  </div>
</template>
<script>
import { defineAsyncComponent} from "vue";
export default {
  name: 'App',
  data(){
   return {
     transition: 'slide'
   }
  },
  provide() {
    return {
      setTransition: (t = 'slide') => this.transition = t,
    }
  },
  computed: {
    appwrapper() {
      return !this.$route.name ? {template: 'loading...'} : defineAsyncComponent(() => import(`./AppWrapper`));
    }
  },
  mounted() {
    if (process.env.NODE_ENV !== 'production')
      document.body.classList.add('style-blinker');
  }
}
</script><style>
  .slide-enter-active,
  .slide-leave-active,
  .slide-back-enter-active,
  .slide-back-leave-active{
    transition: opacity .5s, transform .5s;
  }

  .slide-enter-from{
    opacity: .5;
    transform: translateX(30%);
  }
  .slide-leave-to,
  .slide-back-enter-from{
    opacity: .5;
    transform: translateX(-30%);
  }

  .slide-back-leave-to {
    opacity: .5;
    transform: translateX(30%);
  }
</style>