// import { createApp } from 'vue'
import { createStore } from 'vuex';

// Create a new store instance.
const store = createStore({
  state() {
    return {
      vehicleNameArr: [],
      showNetworkError: false,
      vehicleFormData: {
        days: 0,
        oneWayDistance: 0,
        vehicleIndex: 0,
        annualMil: 0,

      },
      calcs: [{
        index: 0,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 1,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 2,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 3,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 4,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 5,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 6,
        days: 0,
        miles: 0,
        annualMileage: 0,
      },
      {
        index: 7,
        days: 0,
        miles: 0,
        annualMileage: 0,
      }],
      selectedPaymentPlan: '',
      vgsIndexes: {},
      customHeader: '',
      selectedPaymentPlanRadio: '',
      loaderStatus: false,
      rc2ErrorMessage: '',
      ruleExecutionStatus: false,
      isPolicyCoverageEdited: false,
      isVehicleCoverageEdited: false,
        quoteId: ''
    }
  },
  mutations: {
    setCustomHeader(state, customHeaderVal) {
      state.customHeader = customHeaderVal;
    },
    setVgsIndexes(state, obj) {
      state.vgsIndexes = obj;
    },
    /** set vehicle name data */
    setVehicleNameData(state, vehNameData) {
      state.vehicleNameArr.push(vehNameData);
    },
    setNetworkError(state, flag) {
      state.showNetworkError = flag;
    },
    setVehicleFormDataDays(state, formData) {
      const vehDataArr = formData.vehicleFormData.field_name.split('_');
      const vehIndex = vehDataArr[2];
      for (let vCnt = 0; vCnt < state.calcs.length; vCnt++) {
        if (state.calcs[vCnt].index === parseInt(vehIndex)) {
          state.calcs[vCnt].days = formData.daysValue;
        }
      }
      if (state.calcs[vehIndex].days !== '' || state.calcs[vehIndex].miles !== '') {
        state.calcs[vehIndex].annualMileage = (100 * state.calcs[vehIndex].days * state.calcs[vehIndex].miles) + 5200;
      } else {
        state.calcs[vehIndex].annualMileage = 0;
      }
      //dispatch('annualCalc',  vehIndex );
      // state.vehicleFormData.days = formData.days;
    },
    setVehicleFormDataDistance(state, formData) {
      const vehDataArr = formData.vehicleFormData.field_name.split('_');
      const vehIndex = vehDataArr[2];
      for (let vCnt = 0; vCnt < state.calcs.length; vCnt++) {
        if (state.calcs[vCnt].index === parseInt(vehIndex)) {
          state.calcs[vCnt].miles = formData.milesValue;
        }
      }
      //dispatch('annualCalc', vehIndex );
      if (state.calcs[vehIndex].days !== '' || state.calcs[vehIndex].miles !== '') {
        state.calcs[vehIndex].annualMileage = (100 * state.calcs[vehIndex].days * state.calcs[vehIndex].miles) + 5200;
      } else {
        state.calcs[vehIndex].annualMileage = 0;
      }

    },
  },
  actions: {
    /**
     * set relshChk status through action to differentiate primary driver and additional driver
     */
    setRelshChk({ state }, relStauts) {
      state.relshChk = relStauts;
    },
    /**
     * set tranSpsChk status through action
     */
    setTransSpsChk({ state }, tranStatus) {
      state.tranSpsChk = tranStatus;
    },
    /**
     * set rule execution status
     */
    setRuleExeStatus({ state }, ruleStatus) {
      state.ruleExecutionStatus = ruleStatus;
    },
    /**
     * set rc2 error message from api response to store.
     * @param {*} param0 
     * @param {*} customHeaderVal 
     */
    setRC2ErrorMsg({ state }, errorMsg) {
      state.rc2ErrorMessage = errorMsg;
    },
    customHeaderAction({ commit }, customHeaderVal) {
      commit('setCustomHeader', customHeaderVal);
    },
    setVgsIndexes({ commit }, obj) {
      commit('setVgsIndexes', obj);
    },
    annualCalc({ state }, vehIndex) {
      // set annual mileage in store
      state.calcs[vehIndex].annualMileage = (100 * state.calcs[vehIndex].days * state.calcs[vehIndex].miles) + 5200;
    },
    netWorkErrorAction({ commit }, stateData) {
      commit('setNetworkError', stateData);
    },
    vehicleFormDataDaysAction({ commit }, vehData) {

      commit('setVehicleFormDataDays', vehData);
    },
    vehicleFormDataDistanceAction({ commit }, formData) {
      commit('setVehicleFormDataDistance', formData);
    },
    vehicleNameData({ commit }, vehNameData) {
      commit('setVehicleNameData', vehNameData);
    },
    /** update store data for vehicle names array  */
    updateVehicleNameData({ state }, vehNameData) {
      state.vehicleNameArr[vehNameData.index].vehicleName = vehNameData.vehicleName;
    },
    /** set selected payment plan in store to acess on summary section */
    setPaymentPlan({ state }, paymentData) {
      state.selectedPaymentPlan = paymentData;
      console.log('store data values after payment plan selection', state);
    },
    setPaymentPlanRadio({ state }, selectedPaymentPlanRadio) {
      state.selectedPaymentPlanRadio = selectedPaymentPlanRadio;
    },
    setLoaderStatus({ state }, status) {
      state.loaderStatus = status;
    },
    setPolicyCoverageEdited({ state }, status) {
      state.isPolicyCoverageEdited = status
    },
    setVehicleCoverageEdited({ state }, status) {
      state.isVehicleCoverageEdited = status
    },
    setQuoteId({ state }, quoteId) {
      state.quoteId = quoteId;
    },
  },
  getters: {
    getCustomHeader(state) {
      return state.customHeader;
    },
    getNetworkErrorStatus(state) {
      return state.showNetworkError;
    },
    getVgsIndexes(state) {
      return state.vgsIndexes;
    },
    getQuoteId(state) {
      return state.quoteId;
    },
  }

})

export default store
// const app = createApp({ /* your root component */ })

// Install the store instance as a plugin
// app.use(store)