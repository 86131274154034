  /* eslint-disable */
import { createApp } from 'vue'
import Maska from 'maska'
import money from 'v-money3'
import router from './router'
import App from './App.vue'
import store from './store/store';
  import VueMobileDetection from "vue-mobile-detection";
  import 'hacktimer'

import VueSmoothScroll from 'vue3-smooth-scroll'
const app = createApp(App);  
app.use(router).use(store).use(Maska).use(money).use(VueSmoothScroll).use(VueMobileDetection).mount('#app')
app.config.globalProperties.console = console
app.config.globalProperties.window = window

// console.log('window.location.hostname', window.location.hostname);
// need to modify this section of code to load theme in correct way
// if (window.location.hostname.includes('gg')) {
  const activeThemeObj = require('../config/agency/config.js'); // import activeTheme from ;
  app.config.globalProperties.$theme = activeThemeObj.default;
// } else {
 // console.log('loadeddd active theme');
  // const activeThemeObj = require('../config/agency/config.js'); // import activeTheme from ;
  // app.config.globalProperties.$theme = activeThemeObj.default;
// }


app.config.globalProperties.$router = router;


import "bootstrap/js/dist/popover.js"
import "bootstrap/dist/js/bootstrap.js"
