import { createWebHistory, createRouter } from "vue-router";
const Waffle = () => import('../components/waffle/Waffle');
const ComponentTests = () => import("../components/UI/tests/ComponentTests");
const LandingPageNA = () => import("../components/template/views/layout/LandingPageNA.vue");
const IntroSection = () => import("../components/template/views/layout/IntroPage.vue");
const VehicleSection = () => import("../components/template/views/layout/VehiclePage.vue");
const DriverSection = () => import("../components/template/views/layout/DriverPage.vue");
const AutoDetailsSection = () => import("../components/template/views/layout/AutoDetailsPage.vue");
const ThankyouPage = () => import("../components/template/views/layout/ThankyouPage.vue");
const NotFound = () => import("../components/template/views/layout/ErrorPage.vue");
const VehiclePageRc2 = () => import("../components/template/views/layout/VehiclePageRc2.vue");
const DriverPageRc2 = () => import("../components/template/views/layout/DriverPageRc2.vue");
const PolicyDetailsRc2 = () => import("../components/template/views/layout/PolicyDetailsRc2.vue");
const CheckoutPage = () => import("../components/template/views/layout/CheckoutPage.vue");
const ConfirmationPage = () => import("../components/template/views/layout/ConfirmationPage.vue");
const RedirectPage = () => import("../components/template/views/layout/RedirectPage.vue");

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   redirect: "landing",
  // },
  {
    path: "/businessinsurance/:pathMatch(.*)?",
    name: "businessinsurance",
    component: Waffle,
  },
  {
    path: "/waffle/:pathMatch(.*)*",
    name: "Waffle",
    component: Waffle,
  },
  {
    path: "/tests/components",
    name: "ComponentTests",
    component: ComponentTests,
  },
  {
    path: "/landing",
    name: "Landing",
    component: LandingPageNA,
  },
  {
    path: "/auto/insurance-info",
    name: "insurance-info",
    component: IntroSection,
  },
  {
    path: "/auto/vehicle-info",
    name: "vehicle-info",
    component: VehicleSection,
  },
  {
    path: "/auto/add-vehicle",
    name: "add-vehicle",
    component: VehicleSection,
  },
  {
    path: "/auto/driver-info",
    name: "driver-info",
    component: DriverSection,
  },
  {
    path: "/auto/add-driver",
    name: "add-driver",
    component: DriverSection,
  },
  {
    path: "/auto/driver-summary",
    name: "driver-summary",
    component: DriverSection,
  },
  {
    path: "/auto/details-info",
    name: "details-info",
    component: AutoDetailsSection,
  },
  {
    path: "/auto/incident-summary",
    name: "incident-summary",
    component: AutoDetailsSection,
  },
  {
    path: "/auto/incident-details",
    name: "incident-details",
    component: AutoDetailsSection,
  },
  {
    path: "/auto/thankyouPage",
    name: "thankyouPage",
    component: ThankyouPage,
  },
  {
    path: "/auto/thankyouPage/Click/",
    name: "redirectPage",
    component: RedirectPage,
  },
  {
    path: "/auto/vehicle-info-rc2",
    name: "vehicle-info-rc2",
    component: VehiclePageRc2,
  },
  {
    path: "/auto/driver-info-rc2",
    name: "driver-info-rc2",
    component: DriverPageRc2,
  },
  {
    path: "/auto/policy-details-rc2",
    name: "policy-details-rc2",
    component: PolicyDetailsRc2,
  },
  {
    path: "/auto/checkout",
    name: "checkout",
    component: CheckoutPage,
  },
  {
    path: "/auto/confirmation",
    name: "confirmation",
    component: ConfirmationPage,
  },
  /**
   * Non-matched routes match this route. For analytics purposes, since an actual `404` HTTP
   * code can't be returned from the API while navigating through the single-page application,
   * the URL is updated with a hash value and the title is update with a human-readable value.
   * These values can be filtered in analytics software.
   */
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'not-found',
    meta: { hash: '404-not-found', pageTitle: '404 Not Found' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(){
    return false
  }
});

export default router;